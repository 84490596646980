




























































































































































































































































































































































































































































































::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    .purchase-order-list-table {
        .v-data-table__mobile-row {
            width: calc(100vw - 12px - 1px - 1px - 12px);
        }
        .v-data-table__mobile-row__header {
            width: 80px !important;
        }
        .v-data-table__mobile-row__cell {
            width: calc(100% - 16px - 80px - 16px) !important;
        }
    }
}
