
































































::v-deep {
    .border-bottom-0 .v-input__slot::before {
        content: none;
    }

    .text-center-field input {
        text-align: center;
    }
}
