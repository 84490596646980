











































::v-deep {
    .v-data-table__mobile-table-row {
        display: block;
        padding: 12px 0;
    }
    .v-data-table__mobile-row {
        height: 24px !important;
        min-height: 24px;
    }

    .v-input__slot::before {
        content: none !important;
    }
    .v-input__append-inner {
        display: none;
    }
    input {
        font-size: 0.875rem;
        text-align: end;
    }
}
