





































::v-deep {
    .v-text-field {
        &__prefix,
        &__suffix {
            font-size: 12px;
        }
    }
    input {
        min-width: auto !important;
        text-align: center;
    }
}
