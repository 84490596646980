

































































































































































::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .z-index-3 {
        z-index: 3 !important;
    }
    .sticky-left {
        position: sticky;
        left: 0;
        background-color: white;
    }
}
