























































































































































































































::v-deep {
    .v-data-table__mobile-table-row {
        display: block;
        padding-top: 12px;
    }
    .v-data-table__mobile-row {
        height: auto !important;
        min-height: 24px;

        &:last-child {
            margin-top: 12px;
        }
    }
}
