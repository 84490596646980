




































































































































::v-deep {
    .v-data-table__wrapper {
        flex: 1 0 auto;
    }
    .v-data-footer {
        background-color: white !important;
        margin-right: 0 !important;
        margin-top: -1px !important;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .max-width-0 {
        max-width: 0 !important;
    }
}
