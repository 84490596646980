


































































































::v-deep {
    input {
        text-align: center;
    }
}
.hasCertification ::v-deep input {
    cursor: pointer !important;
}
