



























































































































































































































::v-deep td.text-truncate {
    max-width: 1px;
}
